<template>
  <div>
    <div class="_SelectHeader">
      <div class="_Select">
        <span style="width: 100px">申请人：</span>
        <el-input v-model="personName" @input="blurInput"></el-input>
      </div>
      <!-- <el-button style="background: #1263ff; color: #ffffff"
        >通过申请</el-button
      >
      <el-button style="background: #1263ff; color: #ffffff"
        >拒绝申请</el-button
      > -->
    </div>
    <div class="_TableBox">
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight"
      >
        <el-table-column prop="saveUserName" label="申请人" width="280">
        </el-table-column>
        <el-table-column prop="compName" label="申请加入公司">
        </el-table-column>
        <el-table-column prop="saveTime" label="申请时间">
          <template slot-scope="scope">
            <span>{{ new Date(scope.row.saveTime).toLocaleString() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-button
                :disabled="scope.row.agreeStatus == 1"
                v-if="scope.row.agreeStatus == 1 || scope.row.agreeStatus == null"
                @click="handleApplication(scope.row.id, 1)"
                :class="{ disabledColor: scope.row.agreeStatus == 1 }"
                style="background: none; border: none; color: #4d81f9"
                >通过申请</el-button
              >
              <el-button
                v-if="scope.row.agreeStatus == 0 || scope.row.agreeStatus == null"
                :disabled="scope.row.agreeStatus == 0"
                @click="handleApplication(scope.row.id, 0)"
                :class="{ disabledColor: scope.row.agreeStatus == 0 }"
                style="background: none; border: none; color: #cf4526"
                >拒绝申请</el-button
              >
              <img
                src="../../assets/images/del.png"
                @click="deleteItem(scope.row, scope.$index)"
                style="margin-left: 10px; width: 30px; height: 30px"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { get, put, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      tableData2: [],
      personName: "",
      start: 0,
      end: 10,
      currentPage: 1,
      curSize: 10,
      pageSize: 1,
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return new Date(e.saveTime).toLocaleTimeString();
    },
  },
  mounted() {
    this.getDataList();
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 90 + 40);
      });
    },

    getDataList() {
      get(
        "/api/ApplycompHis/GetByAgreeUserId?AgreeUserId=" +
          JSON.parse(sessionStorage.getItem("userInfo")).id
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
          this.tableData2 = JSON.parse(JSON.stringify(resp.data));
        }
      });
    },
    handleApplication(id, agreeStatus) {
      put("/api/ApplycompHis?Id=" + id + "&AgreeStatus=" + agreeStatus)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: agreeStatus == 1 ? "通过成功" : "拒绝成功",
            });
            this.getDataList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(agreeStatus == 1 ? "通过失败" : "拒绝失败");
        });
    },
    deleteItem(row, index) {
      this.$confirm(
        "您确认要删除" + row.saveUserName + "加入" + row.compName + "这条申请?"
      )
        .then(() => {
          deleteMethod("/api/ApplycompHis?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.tableData.splice(index, 1);
                this.getDataList();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    blurInput() {
      this.tableData = [];
      this.tableData2.map((i) => {
        if (i.saveUserName == this.personName) {
          this.tableData.push(i);
        }
      });
      if (!this.personName) {
        this.tableData = this.tableData2;
      }
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
  },
};
</script>

<style>
.disabledColor {
  color: silver !important;
}
</style>